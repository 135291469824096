<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Setting",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    return { Toast, toBase64 };
  },
  data() {
    return {
      title: "Setting",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Setting",
          active: true,
        },
      ],
      updateFrom: {
        firstname: "",
        surename: "",
        name: "",
        email: "",
        username: "",
        phone: "",
        role: 0,
        avatar: null,
      },
      changePass: {
        password: "",
        c_password: "",
      },
      value: ["javascript"],
      date: null,
      user: JSON.parse(localStorage.getItem("user")),
      secure:
        "?ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt"),
    };
  },
  methods: {
    async update() {
      var url =
        process.env.VUE_APP_URL_API +
        "agent-api/updateAgent/" +
        this.user.ID +
        this.secure;
      const updateUser = await axios.post(url, JSON.stringify(this.updateFrom));
      const res = updateUser.data;

      if (res.Meta.Code !== 200) {
        this.Toast.fire({
          icon: "error",
          title: res.Meta.Message,
        });
        return;
      }

      this.Toast.fire({
        icon: "success",
        title: res.Meta.Message,
      });

      res.Data["AccessToken"] = localStorage.getItem("jwt");
      localStorage.setItem("user", JSON.stringify(res.Data));
      localStorage.setItem("userdata", JSON.stringify(res.Data));
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    async updatePass() {
      const formData = new FormData();
      formData.append("password", this.changePass.password);
      formData.append("v_pass", this.changePass.c_password);
      var url =
        process.env.VUE_APP_URL_API +
        "agent-api/changePassword/" +
        this.user.ID +
        this.secure;
      const ubahPass = await axios.post(url, formData);
      const res = ubahPass.data;

      if (res.Meta.Code !== 200) {
        this.Toast.fire({
          icon: "error",
          title: res.Meta.Message,
        });
        return;
      }

      this.changePass = {
        password: "",
        c_password: "",
      };

      this.Toast.fire({
        icon: "success",
        title: res.Meta.Message,
      });
    },
    async pickImage() {
      var base64 = await this.toBase64(this.$refs.file.files[0]);
      this.user.Avatar.Original = base64;
      this.updateFrom.avatar = this.$refs.file.files[0];
    },
    async uploadAvatar() {
      if (this.updateFrom.avatar === null) {
        this.Toast.fire({
          icon: "error",
          title: "Please select image",
        });
        return;
      }

      const formData = new FormData();
      formData.append("Avatar", this.updateFrom.avatar);

      var url =
        process.env.VUE_APP_URL_API +
        "agent-api/uploadAvatar/" +
        this.user.ID +
        this.secure;
      const uploadAva = await axios.post(url, formData);
      const res = uploadAva.data;

      if (res.Meta.Code !== 200) {
        this.Toast.fire({
          icon: "error",
          title: res.Meta.Message,
        });
        return;
      }

      this.Toast.fire({
        icon: "success",
        title: res.Meta.Message,
      });

      localStorage.setItem("userdata", JSON.stringify(res.Data));
      localStorage.setItem("user", JSON.stringify(res.Data));

      var navAvatar = document.getElementById("avatarNavbar");
      navAvatar.setAttribute("src", res.Data.Avatar.Original);

      this.updateFrom.avatar = null;
    },
  },
  beforeMount() {
    this.updateFrom = {
      firstname: this.user.Name,
      surename: "",
      name: this.user.Name,
      username: this.user.Username,
      email: this.user.Email,
      phone: this.user.Phone,
      role: this.user.Role.ID,
      avatar: null,
    };
  },
  components: {
    Layout,
    // Multiselect,
    flatPickr,
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="@/assets/images/profile-bg.jpg"
          class="profile-wid-img"
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  :src="user.Avatar.Original"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                  alt="user-profile-image"
                />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    id="profile-img-file-input"
                    ref="file"
                    @change="pickImage"
                    type="file"
                    class="profile-img-file-input"
                  />
                  <label
                    for="profile-img-file-input"
                    class="profile-photo-edit avatar-xs"
                  >
                    <span
                      class="avatar-title rounded-circle bg-light text-body shadow"
                    >
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">{{ user.Name }}</h5>
              <p class="text-muted mb-0">{{ user.Role.Title }}</p>
              <div class="col-lg-12">
                <div class="text-end">
                  <button
                    @click="uploadAvatar"
                    type="submit"
                    class="btn btn-success"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#personalDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Personal Details
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#changePassword"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  Change Password
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <form action="javascript:void(0);" @submit="update">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label"
                          >First Name</label
                        >
                        <input
                          v-model="updateFrom.firstname"
                          type="text"
                          class="form-control"
                          id="firstnameInput"
                          placeholder="Enter your firstname"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastnameInput" class="form-label"
                          >Username</label
                        >
                        <input
                          v-model="updateFrom.username"
                          type="text"
                          class="form-control"
                          id="lastnameInput"
                          placeholder="Enter your lastname"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="phonenumberInput" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          v-model="updateFrom.phone"
                          type="text"
                          class="form-control"
                          id="phonenumberInput"
                          placeholder="Enter your phone number"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Email Address</label
                        >
                        <input
                          v-model="updateFrom.email"
                          type="email"
                          class="form-control"
                          id="emailInput"
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="JoiningdatInput" class="form-label"
                          >Joining Date</label
                        >

                        <flat-pickr
                          v-model="date"
                          class="form-control"
                        ></flat-pickr>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Updates
                        </button>
                        <!-- <button type="button" class="btn btn-soft-success">
                          Cancel
                        </button> -->
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form action="javascript:void(0);" @submit="updatePass">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label"
                          >New Password*</label
                        >
                        <input
                          v-model="changePass.password"
                          type="password"
                          class="form-control"
                          id="newpasswordInput"
                          placeholder="Enter new password"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label"
                          >Confirm Password*</label
                        >
                        <input
                          v-model="changePass.c_password"
                          type="password"
                          class="form-control"
                          id="confirmpasswordInput"
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <a
                          href="javascript:void(0);"
                          class="link-primary text-decoration-underline"
                          >Forgot Password ?</a
                        >
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success">
                          Change Password
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
